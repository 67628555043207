import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import { ScalableWrapper, FeatureSection } from './scalable.style';
import Button from 'reusecore/src/elements/Button';

const ScalableHistory = ({
  row,
  col,
  title,
  description,
  sectionSubTitle,
  cardArea,
  featureTitleStyle,
  featureDescriptionStyle,
  btnStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        MY_BETS_SCREENSHOT {
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          alt
        }
        ANYONE_FEATURES {
          title
          des
          image {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <ScalableWrapper id="scalable">
      <Container noGutter mobileGutter>
        <Box className="row" {...row}>
          <Box className="colleft" {...col} style={{ flexDirection: 'column' }}>
            <Text
              content="The Best Tool for Beginners and Experts"
              {...sectionSubTitle}
            />
            <FeatureBlock
              title={
                <Heading content="Anyone can do Matched Betting" {...title} />
              }
              description={
                <>
                  <Text
                    content="You don't need any experience with betting or sports at all.
                  We show you exactly what you need to do in order to make money online
                  with matched betting!"
                    {...description}
                  />
                  <a href="https://app.oddshero.io/signup">
                    <Button
                      title="Try it Now"
                      className="countDownMainButton"
                      {...btnStyle}
                    />
                  </a>
                </>
              }
            />
            <FeatureSection>
              {Data.cryptoJson.ANYONE_FEATURES.map((item, index) => (
                <div key={`feature-${index}`} className="featureWrapper">
                  <Fade up>
                    <Image src={item.image.publicURL} alt={item.title} />
                    <Box className="contextPortion">
                      <Text content={item.title} {...featureTitleStyle} />

                      <Text content={item.des} {...featureDescriptionStyle} />
                    </Box>
                  </Fade>
                </div>
              ))}
            </FeatureSection>
          </Box>
          <Box className="colright" {...col} {...cardArea}>
            <Img
              fluid={Data.cryptoJson.MY_BETS_SCREENSHOT.image.childImageSharp.fluid}
              className="ScalableImage"
              style={{width: "100%"}}
              alt={Data.cryptoJson.MY_BETS_SCREENSHOT.alt}
            />
          </Box>
        </Box>
      </Container>
    </ScalableWrapper>
  );
};

// Transactions style props
ScalableHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object,
};

// Scalable default style
ScalableHistory.defaultProps = {
  // Scalable section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Scalable section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1 / 2, 1 / 2, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  // Scalable section title default style
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
  },
  // Scalable section description default style
  description: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  sectionSubTitle: {
    as: 'span',
    textAlign: 'left',
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '27px',
    color: '#525f7f',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    mb: ['25px', '25px', '30px', '30px', '45px'],
  },
  featureTitleStyle: {
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['1', '1', '1', '1', '1'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '10px',
  },
  // Scalable section description default style
  featureDescriptionStyle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '27px',
  },
};

export default ScalableHistory;
