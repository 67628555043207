import AuthorOne from '../../assets/image/crypto/author-4.jpg';
import AuthorTwo from '../../assets/image/crypto/author-2.jpg';
import AuthorThree from '../../assets/image/crypto/author-3.jpg';
import AuthorFour from '../../assets/image/crypto/author-1.jpg';
import FEATURE1 from '../../assets/image/crypto/tf1.svg';
import FEATURE2 from '../../assets/image/crypto/tf2.svg';
import FEATURE3 from '../../assets/image/crypto/tf3.svg';
import FEATURE4 from '../../assets/image/crypto/tf4.svg';
import PROOF1 from '../../assets/image/crypto/proof1.svg';
import PROOF2 from '../../assets/image/crypto/proof2.svg';
import PROOF3 from '../../assets/image/crypto/proof3.svg';
import PROOF4 from '../../assets/image/crypto/proof4.svg';
import PROOF5 from '../../assets/image/crypto/proof5.svg';
import PROOF6 from '../../assets/image/crypto/proof6.svg';
import JACKPOTIMG from '../../assets/image/crypto/jackpot.svg';
import BETA1 from '../../assets/image/crypto/beta-1.svg';
import BETA2 from '../../assets/image/crypto/beta-2.svg';
import BETA3 from '../../assets/image/crypto/beta-3.svg';
import { ic_monetization_on } from 'react-icons-kit/md/ic_monetization_on';
import { ic_settings } from 'react-icons-kit/md/ic_settings';
import { pieChart } from 'react-icons-kit/icomoon/pieChart';
import { briefcase } from 'react-icons-kit/fa/briefcase';
import SignupBonusScreenshot from '../../assets/image/crypto/signup-bonus-bwin.png';
import BonusScreenshot from '../../assets/image/crypto/Screenshot_Tradefeed.png';
import CoverScreenshot from '../../assets/image/crypto/Screenshot_TradefeedCover.png';
import ProfitScreenshot from '../../assets/image/crypto/Screenshot_Dashboard.png';

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Feature',
    path: '#trusted',
    offset: '0',
  },
  {
    label: 'Offers',
    path: '#scalable',
    offset: '-10',
  },

  {
    label: 'Payment Proofs',
    path: '#featureslider',
    offset: '-10',
  },
  {
    label: 'Contact Us',
    path: '#footerSection',
    offset: '380',
  },
];

export const TESTIMONIALS = [
  {
    review:
      'Best working experience  with this amazing team & in future, we want to work together',
    name: 'Jon Doe',
    designation: 'CEO of Dell Co.',
    avatar: `${AuthorOne}`,
  },
  {
    review:
      'Impressed with master class support of the team and really look forward for the future.',
    name: 'Jon Doe',
    designation: 'Co Founder of IBM',
    avatar: `${AuthorTwo}`,
  },
  {
    review:
      'I have bought more than 10 themes on ThemeForest, and this is the first one I review.',
    name: 'Jeny Doe',
    designation: 'Manager of Hp co.',
    avatar: `${AuthorThree}`,
  },
  {
    review:
      'Impressed with master class support of the team and really look forward for the future.',
    name: 'Jon Doe',
    designation: 'Manager of Hp co.',
    avatar: `${AuthorFour}`,
  },
];
export const TRANSACTIONS_FEATURE = [
  {
    image: FEATURE1,
    title: 'Create Payment Address',
    des: 'Provide your payout wallet address and callback URL to PayBear API.',
  },
  {
    image: FEATURE2,
    title: 'Ask for Payment',
    des: 'Show your customer the wallet address as well as the payment amount.',
  },
  {
    image: FEATURE3,
    title: 'Get Paid',
    des: 'Payment is sent to the payout wallet immediately.',
  },
  {
    image: FEATURE4,
    title: 'Get Payment Notification.',
    des:
      'Callbacks are sent to the URL you specified. You can process customer order',
  },
];
export const PROOFS_FEATURE = [
  {
    image: PROOF1,
    title: 'Save Time',
    des: 'We find the bets for you',
  },
  {
    image: PROOF2,
    title: 'No hidden fees',
    des: 'know our fees upfront.',
  },
  {
    image: PROOF3,
    title: 'Secure storage',
    des: 'Sleep with peace of mind.',
  },
  {
    image: PROOF4,
    title: 'Systematic trading',
    des: 'History intraday market.',
  },
  {
    image: PROOF5,
    title: 'Network Effect',
    des: 'Casinos contribute 1%.',
  },
  {
    image: PROOF6,
    title: 'Bigger Rewards',
    des: 'Players are incentivized.',
  },
];
export const SCALABLE_FEATURE = [
  {
    image: JACKPOTIMG,
    title: 'Daily Jackpot',
    des: '35000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Weekly Jackpot',
    des: '250000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Monthly Jackpot',
    des: '4999697 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Yearly Jackpot',
    des: '300245785000 CLV',
  },
];

export const BETA_FEATURE = [
  {
    image: BETA1,
    title: 'SEPA Transfers',
    des: 'Deposit & Withdraw money.',
  },
  {
    image: BETA2,
    title: '24/7 Support',
    des: 'Always here for you.',
  },
  {
    image: BETA3,
    title: 'Secure',
    des: 'Your money is safe.',
  },
];

export const menuWidget = [
  {
    id: 1,
    title: 'About Us',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'Support Center',
      },
      {
        id: 2,
        url: '#',
        text: 'Customer Support',
      },
      {
        id: 3,
        url: '#',
        text: 'About Us',
      },
      {
        id: 4,
        url: '#',
        text: 'Copyright',
      },
      {
        id: 5,
        url: '#',
        text: 'Popular Campaign',
      },
    ],
  },
  {
    id: 2,
    title: 'Our Information',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'Return Policy',
      },
      {
        id: 2,
        url: '#',
        text: 'Privacy Policy',
      },
      {
        id: 3,
        url: '#',
        text: 'Terms & Conditions',
      },
      {
        id: 4,
        url: '#',
        text: 'Site Map',
      },
      {
        id: 5,
        url: '#',
        text: 'Store Hours',
      },
    ],
  },
  {
    id: 3,
    title: 'My Account',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'Press inquiries',
      },
      {
        id: 2,
        url: '#',
        text: 'Social media directories',
      },
      {
        id: 3,
        url: '#',
        text: 'Images & B-roll',
      },
      {
        id: 4,
        url: '#',
        text: 'Permissions',
      },
      {
        id: 5,
        url: '#',
        text: 'Speaker requests',
      },
    ],
  },
];
export const Language_NAMES = [
  {
    label: 'English',
    value: 'eng',
  },
  {
    label: 'Chinese',
    value: 'chinese',
  },
  {
    label: 'Indian',
    value: 'indian',
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    name: 'Basic Account',
    description: 'For beginners to clear the first bonus ',
    price: 'FREE',
    priceLabel: 'Forever',
    buttonLabel: 'Get Started Now',
    url: 'https://app.oddshero.io/signup',
    listItems: [
      {
        content: 'Only Bet365 available',
      },
      {
        content: 'Free Bets Tracker',
      },
      {
        content: 'Unlimited Matched Bets',
      },
      {
        content: 'Only Back Bets',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For experts to clear thousands of EUR in bonuses ',
    price: '14.99 EUR',
    priceLabel: 'Per month',
    buttonLabel: 'Start Earning Now',
    url: 'https://app.oddshero.io/signup',
    listItems: [
      {
        content: 'All 65+ Bookmakers',
      },
      {
        content: 'Free Bets Tracker',
      },
      {
        content: 'Unlimited Bets',
      },
      {
        content: 'Including Lay Bets',
      },
      {
        content: 'Instant Support via Chat',
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    name: 'Basic Account',
    description: 'For beginners to clear the first bonus ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'Start for free',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Business Account',
    description: 'For Small teams or group who need to build website ',
    price: '$6.00',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'Register Now',
    url: '#',
    trialButtonLabel: 'Or Start 14 Days trail',
    trialURL: '#',
    listItems: [
      {
        content: 'Unlimited secure storage',
      },
      {
        content: '2,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: '24/7 phone support',
      },
      {
        content: '50+ Webmaster Tools',
      },
    ],
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$9.99',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'Register Now',
    url: '#',
    trialButtonLabel: 'Or Start 14 Days trail',
    trialURL: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '3,000s of Templates Ready',
      },
      {
        content: 'Advanced branding',
      },
      {
        content: 'Knowledge base support',
      },
      {
        content: '80+ Webmaster Tools',
      },
    ],
  },
];

export const SCREENSHOTS = [
  {
    icon: ic_monetization_on,
    title: 'Claim Bonus Offer',
    description:
      'All major bookmakers offer <b>large signup bonuses to attract new customers</b>.<br/>For example, if you deposit 100 EUR they will give you another 100 EUR on top!<br/>However, before you can withdraw 200 EUR you will need to <b>meet some betting requirements.</b><br/><br/><b>Most bettors will fail to do so and lose all their money.</b><br/><br/>We help you <b>double your money</b> with the bonuses and almost no effort! <b>Not just once or twice, but 65+ times!</b>',
    image: SignupBonusScreenshot,
  },
  {
    icon: ic_settings,
    title: 'Place Bonus Bets',
    description:
      'Once you signed up with a bookmaker and received your bonus we will show you <b>risk-free bets with which you can fulfill the turnover requirements to withdraw the bonus.</b><br/><br/>Simply visit the bookmaker, place the recommended bet and register the bet with us to keep track of it. <b>This should not take more than 1-2 minutes.</b>',
    image: BonusScreenshot,
  },
  {
    icon: pieChart,
    title: 'Place Cover Bets',
    description:
      'In order to remove all risk from your bet, you will now place the opposite on Sportmarket.<br/><b>This way it does not matter who wins the match, as you have bets on both sides.</b><br/><br/>With this logic you can <b>clear any bonus without any risk of losing money.</b><br/><br/>Register this bet with us as well and we will show your profit over time on the dashboard.',
    image: CoverScreenshot,
  },
  {
    icon: briefcase,
    title: 'Repeat and Profit',
    description:
      'You can repeat that process with all your bonuses and <b>clear them all in no time, with almost no effort.</b><br/>Even if you lose some EUR on the small bets to create your turnover, you will win a lot more when clearing the bonus!<br/><br/><b>There is no more effective and efficient way to clear your bonuses and no easier way to make money with sports betting than this!</b>',
    image: ProfitScreenshot,
  },
];
