import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import 'rc-tabs/assets/index.css';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';

import SectionWrapper from './updateScreen.style';
//import { SCREENSHOTS } from 'common/src/data/Crypto';


const UpdateScreen = ({ secTitleWrapper, secText, secHeading, description }) => {

  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        SCREENSHOTS {
          icon
          title
          description
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  console.log(Data)

  return (
    <SectionWrapper id="screenshot_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="Learn Matched Betting" />
          <Heading
            {...secHeading}
            content="See how easy you can profit from signup bonuses. No prior betting experience required!"
          />
        </Box>
        <Tabs
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent animated={false} />}
          className="update-screen-tab"
        >
          {Data.cryptoJson.SCREENSHOTS.map((item, index) => (
            <TabPane
              tab={
                <>
                  {/*<Icon icon={item.icon} size={24} />&nbsp;&nbsp;*/}
                  {item.title}
                </>
              }
              key={index + 1}
            >
              <Box style={{marginTop: "25px", marginLeft: "50px", marginRight: "50px"}}>
                <Text {...description}
                  content={<div dangerouslySetInnerHTML={{__html: item.description}} />}
                />
              </Box>
              <Img
                fluid={item.image.childImageSharp.fluid}
                alt={`screenshot-${index + 1}`}
                loading="lazy"
              />
            </TabPane>
          ))}
        </Tabs>
      </Container>
    </SectionWrapper>
  );
};

UpdateScreen.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
};

UpdateScreen.defaultProps = {
  secTitleWrapper: {
    mb: ['60px', '80px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#ff4362',
    mb: '12px',
  },
  description: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    maxWidth: "100%",
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '540px',
    maxWidth: '100%',
  },
};

export default UpdateScreen;
